var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-link',{attrs:{"href":"/"}},[_c('b-img',{staticClass:"mb-3",staticStyle:{"width":"200px"},attrs:{"src":require('@/assets/images/avatars/optimalfull.png')}})],1),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Silahkan Registrasi ")]),_c('b-form',{staticClass:"auth-register-form mt-2",attrs:{"method":"post","enctype":"multipart/form-data"},on:{"submit":_vm.addUser}},[_c('validation-observer',{ref:"registerForm"},[_c('b-form-group',{attrs:{"label":"Email","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"Masukkan Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Password")])]),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"Masukkan Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama Lengkap","label-for":"register-nama"}},[_c('validation-provider',{attrs:{"name":"Nama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-nama","name":"register-nama","state":errors.length > 0 ? false : null,"placeholder":"Masukan Nama Lengkap"},model:{value:(_vm.nama),callback:function ($$v) {_vm.nama=$$v},expression:"nama"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Kampus","label-for":"register-kampus"}},[_c('validation-provider',{attrs:{"name":"Kampus","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"register-kampus","state":errors.length > 0 ? false : null,"placeholder":"Pilih Kampus","value":_vm.kampus,"reduce":function (nama) { return nama.nama; },"options":_vm.listKampus,"label":"nama"},model:{value:(_vm.kampus),callback:function ($$v) {_vm.kampus=$$v},expression:"kampus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Jurusan","label-for":"register-jurusan"}},[_c('validation-provider',{attrs:{"name":"Jurusan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"register-jurusan","name":"register-jurusan","state":errors.length > 0 ? false : null,"placeholder":"Jurusan","options":_vm.option_jurusan},model:{value:(_vm.jurusan),callback:function ($$v) {_vm.jurusan=$$v},expression:"jurusan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Strata/Jenjang","label-for":"register-jenjang"}},[_c('validation-provider',{attrs:{"name":"Jenjang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"register-jenjang","name":"register-jenjang","options":_vm.option_jenjang,"state":errors.length > 0 ? false : null,"placeholder":"Kebidanan atau Keperawatan"},model:{value:(_vm.jenjang),callback:function ($$v) {_vm.jenjang=$$v},expression:"jenjang"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"No Telp.","label-for":"register-telepon"}},[_c('validation-provider',{attrs:{"name":"Telepon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-telepon","name":"register-telepon","state":errors.length > 0 ? false : null,"placeholder":"Masukan No Telepon/ HP"},model:{value:(_vm.telepon),callback:function ($$v) {_vm.telepon=$$v},expression:"telepon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-button',{staticStyle:{"width":"100%"},attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addUser($event)}}},[_c('feather-icon',{staticStyle:{"color":"white"},attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_c('b',{staticStyle:{"color":"white"}},[_vm._v(" Daftar")])])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Sudah Memiliki Akun?")]),_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('span',[_vm._v(" Untuk Login")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }